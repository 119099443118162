.root {
    width: 100%;
    height: 40vh;
    position: 'relative';
}

.container {
    width: 100%;
    height: 100%;
    position: 'absolute';
    bottom: 0;
    left: 0;
}