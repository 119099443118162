body {
    background-color: #f9f9f9;
    margin: 0 2%;
    font-family: lato;
}

.container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 30px;
    padding:15px;
    background: #fcfcfc;
    border: 1px solid #fcfcfc;
}

.header {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: row;
}

.t1 {
    color: #00b5cc;
    font-weight: 800;
    padding-right: 30px;
}

.t2 {
    color: #95a5a6;
    font-weight: 300;
}

.midContainer {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mapContainer {
    width: 90%;
    height: 35vh;
    min-height: 260px;
    z-index: 100;
    background: #cdecf9;
    border-radius: 1rem;
    padding: 0rem;
    align-self: center;
    margin: 10px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 770px) {
    .container {
        margin: 0 10%;
    }

    .image {
        width: 100% ;
    }
}
