.container {
    flex:1;
    display: flex;
    flex-direction: column;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 80px;
    align-items: center;
    justify-content: center;
}

.title{
    margin: 0;
    font-weight: 500;
    color: #95a5a6;
}

.name {
    color: #5d5d5d;
}

.confirmed {
    color: rgba(0,0,255,0.5);
    font-size: 2rem !important;
}

.recovered {
    color: #23cba7;
    font-size: 2rem !important;
}

.death {
    color: #f64747;
    font-size: 2rem !important;
}

.date {
    color: #00b5cc;
    font-size: 1.5rem !important;
}
